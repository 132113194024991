.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1;
  display: flow;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.bottom {
  flex: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  border-top: solid 1px var(--adm-color-border);
}