#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 65px;
    line-height: 65px;
}

#components-layout-demo-custom-trigger .logo a {
    height: 65px;
    display: inline-block;
    width: 100%;
}

#components-layout-demo-custom-trigger .logo span {
    color: white;
    font-size: 18px;
    padding-left: 12px;
}

#components-layout-demo-custom-trigger .logo img {
    margin-left: 24px;
    height: 32px;
    margin-bottom: -6px;
}

.ant-layout-sider-collapsed .logo span {
    display: none;
}
